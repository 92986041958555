import { Container, Grid, GridItem ,Text} from "@chakra-ui/react";
import React from "react";

function Footer() {
    return <>

        <Container maxW="4xl" marginTop="100px">
            <Grid templateColumns='repeat(3, 1fr)' gap={10}>
                <GridItem w='100%' ><Text fontSize='xl' as="b">PRODUCT</Text><Text fontSize='md' marginTop="50px">Proposals</Text><Text fontSize='md' >Contracts</Text><Text fontSize='md' >Invoice</Text><Text fontSize='md' >Client CRM</Text><Text fontSize='md' >Time Tracking</Text><Text fontSize='md' >Task Tracking</Text><Text fontSize='md' >Forms</Text><Text fontSize='md' >Accounting</Text><Text fontSize='md' >Bonsai Tax</Text><Text fontSize='md' >Bonsai Cash</Text><Text fontSize='md' marginTop="20px">Pricing</Text><Text fontSize='md' >Bonsai Reviews </Text></GridItem>
                <GridItem w='100%' ><Text fontSize='xl' as="b">FREE RESOURCES</Text><Text fontSize='md' marginTop="50px">Freelance Resources</Text><Text fontSize='md' >Freelance Blog by Bonsai</Text><Text fontSize='md' >How to Write a Contract</Text><Text fontSize='md' >Online Signature Maker</Text><Text fontSize='md' >Self-Employed Taxes Hub</Text><Text fontSize='md' >Self-Employed Tax Calculator</Text><Text fontSize='md' >Self-Employed Tax Deductions</Text><Text marginBottom="50px" fontSize='xl' as="b" >Templates</Text><Text fontSize='md' >About</Text><Text fontSize='md' >Careers</Text><Text fontSize='md' >Support</Text><Text fontSize='md' >LinkedIn</Text><Text fontSize='md' >Twitter</Text><Text fontSize='md' >Privacy policy</Text><Text fontSize='md' >Legal</Text><Text fontSize='md' >Affiliates</Text><Text fontSize='md' >Write for Us</Text></GridItem>
                <GridItem w='100%' ><Text fontSize='xl' as="b">BONSAI</Text><Text fontSize='md' marginTop="50px">About</Text><Text fontSize='md' >Careers</Text><Text fontSize='md' >Support</Text><Text fontSize='md' >LinkedIn</Text><Text fontSize='md' >Twitter</Text><Text fontSize='md' >Privacy policy</Text><Text fontSize='md' >Legal</Text><Text fontSize='md' >Affiliates</Text><Text fontSize='md' >Write for Us</Text></GridItem>

            </Grid>
        </Container>
    </>
}

export default Footer;