import React, { Component } from 'react';
import {Flex,Box ,Spacer,ButtonGroup,Button,HStack,Menu,MenuButton,MenuList,MenuItem,Text} from "@chakra-ui/react"
import { Link ,NavLink } from 'react-router-dom';
import {ChevronDownIcon} from '@chakra-ui/icons'

class Navbar extends Component {
    render() {
        return (
            <>
            <Flex minWidth='max-content' alignItems='center' gap='2' >
  <Box p='2'>
    <NavLink to="/"><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7cd432feb68e_bonsai-logo.svg' alt="xx" /></NavLink>
  </Box>
  <Spacer />
  <HStack gap="2">
     <Menu>
  <MenuButton  rightIcon={<ChevronDownIcon />}>
    <Text>Products</Text>
  </MenuButton>
  <MenuList>
    <MenuItem>Bonsai Workflow
</MenuItem>
    <MenuItem>Bonsai Tax</MenuItem>
    <MenuItem>Bonsai Cash</MenuItem>
    
  </MenuList>
</Menu>
<Menu>
  <MenuButton  rightIcon={<ChevronDownIcon />}>
    Templates
  </MenuButton>
  <MenuList>
    <MenuItem>Complete Templates</MenuItem>
    <MenuItem>Porposal Templates</MenuItem>
    <MenuItem>Invoice Templates</MenuItem>
    <MenuItem>Agreement Template</MenuItem>
    <MenuItem>Quote Template</MenuItem>
    <MenuItem>Scope of Work Template</MenuItem>
    <MenuItem>Brief Template</MenuItem>
  </MenuList>
</Menu>
    <NavLink to ="/Pricing">Pricing</NavLink>
    <Link to ="/">Reviews</Link>
  </HStack>
  <Spacer />
  <ButtonGroup gap='2'>
    <NavLink to="/Login">
    <Button color="#01b289">LOG IN</Button></NavLink>
    <NavLink to="/Login"><Button bg='#01b289' color="white">START FREE</Button></NavLink>
  </ButtonGroup>
</Flex>
</>
        );
    }
}

export default Navbar;