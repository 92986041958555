import {  Button, Container, Divider, FormControl, FormHelperText, FormLabel, Grid, GridItem, Img, Input, Select, Text } from '@chakra-ui/react'
import React, { Component } from 'react'

export default class Login extends Component {
    render() {
        return (
            <>
                <Container marginTop="100px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" maxW="4xl" borderRadius="10px" >
                    <Grid templateColumns='repeat(2, 1fr)'>
                        <GridItem w='60%' marginTop="50px"><Img src='https://app.hellobonsai.com/assets/logos/bonsai-0fe7c417d79806408d8117d5a0ca871f0be3afb1104d54b53f59f53cbda2f28b.svg' /><Text fontSize='md' textAlign="left">Join 500,000+ freelancers and agencies using Bonsai.</Text><Divider orientation='horizontal' marginTop="500px" /><Text fontSize='md' textAlign="left" marginTop="30px">Anyone doing #freelance work should use @bonsaiinc for contracts/payment. It's amazing and saves boatloads of time.</Text><Text fontSize='xs' textAlign="left" marginTop="10px">Nathanael Smith, Product Designer</Text></GridItem>

                        <GridItem w='100%'  >
                            <Container>
                                <Text fontSize='3xl' marginTop="50px" textAlign="left">Try Bonsai free with your Workflow today</Text>
                                <FormControl marginTop="100px">
                                    <FormLabel>Email address</FormLabel>
                                    <Input type='email' placeholder="your@email.com" />
                                    <FormHelperText textAlign="left">We'll never share your email.</FormHelperText>
                                </FormControl>
                                <FormControl >
                                    <FormLabel>FULL NAME</FormLabel>
                                    <Input type='name' placeholder='Mayank' />

                                </FormControl>
                                <FormControl marginTop="20px">
                                    <FormLabel>Password</FormLabel>
                                    <Input type='password' placeholder='Password' />
                                    <FormHelperText textAlign="left">Your password must be at least 6 characters.</FormHelperText>
                                </FormControl>
                                <FormControl marginTop="10px">
                                    <FormLabel>Country</FormLabel>
                                    <Select placeholder='Select country' type="country">
                                        <option>United Arab Emirates</option>
                                        <option>Nigeria</option>
                                        <option>India</option>
                                        <option>USA</option>
                                        <option>United Kingdom</option>
                                        <option>Algeria</option>
                                        <option>Andorra.</option>
                                        <option>Belize</option>
                                        <option>New Zealand</option>
                                        <option>Nigeria</option>
                                        <option>Singapore</option>
                                        <option>Russia</option>
                                    </Select>
                                </FormControl>
                                <Text fontSize='sm' marginTop="10px">By Creating an account,you accept our </Text>
                                <Text fontSize='sm' as="u">Terms and Conditions</Text>
                                <Button bg='#01b289' w='100%' p={2} color='white' marginTop="10px" borderRadius="3px" >
                                    Create Free Account
                                </Button>
                                <Divider orientation='horizontal' marginTop="30px" />
                                <Button bg='white' w='100%' p={2} color='#01b289' border="1px solid #01b289" marginBottom="50px" >
                                    Login
                                </Button>
                            </Container>

                        </GridItem>

                    </Grid>
                </Container>
                <Divider orientation='horizontal' marginTop="30px" />
            </>
        )
    }
}
