import React, { Component } from 'react'
import { Container, Grid, GridItem, Text, Input, Button, Box, TabPanel, TabPanels, Tab, TabList, Tabs, Spacer, Img } from "@chakra-ui/react";
import { NavLink } from 'react-router-dom';

export default class Footer extends Component {
  render() {
    return (
      <>
        <Container maxW='7xl' >
          <Grid templateColumns='repeat(2, 1fr)' gap={6} >
            <GridItem w='70%' marginTop="200px" ><Text as='' fontSize="40px" textAlign="left" >Everything you need to run your business.
            </Text><br /><Text fontSize='xl' textAlign="left">Bonsai’s all-in-one product suite with smart automation lets you focus on your passion, not your paperwork.</Text><br /><Input placeholder='Enter your email' width="auto" border="1px solid #01b289" paddingTop="10px"></Input><NavLink to="/Login"><Button bg='#01b289' color="white">START FREE</Button></NavLink></GridItem>
            <GridItem w='100%' h='10' marginTop="150px" ><img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/60dc499268d597166db997aa_bonsai_hero-opt-2.png" alt='fi' /></GridItem>

          </Grid>
          <Box w='100%' p={4} color='black' marginTop="250px">
            <Text fontSize='4xl'>Make more, manage less</Text>
          </Box>
          <Box w='100%' p={4} color='black' >
            <Text fontSize="20px" >Bonsai integrates and automates every step of your business so it runs seamlessly - from proposal to tax season.</Text>
          </Box>
          <Grid templateColumns='repeat(2, 1fr)' marginTop="50px">
            <GridItem ><Text fontSize='xl'>Without Bonsai</Text><br /><img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c6c51feb665_ill_before_2n%20block.svg" alt="wb" /></GridItem>
            <GridItem ><Text fontSize='xl'>With Bonsai</Text><br /><img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c9ed8feb675_ill_after_2nd%20block.svg" alt="wbw" /></GridItem>
          </Grid>
          {/* <Box>
          <Grid templateRows='repeat(8, 1fr)'
           gap={6}>
            <GridItem w='100%' ><Text fontSize='3xl'>(3xl) In love with React & Next</Text></GridItem>
            <GridItem w='100%' ><Text fontSize='3xl'>(3xl) In love with React & Next</Text></GridItem>
            <GridItem w='100%' ><Text fontSize='3xl'>(3xl) In love with React & Next</Text></GridItem>
            <GridItem w='100%' ><Text fontSize='3xl'>(3xl) In love with React & Next</Text></GridItem>
            <GridItem w='100%' ><Text fontSize='3xl'>(3xl) In love with React & Next</Text></GridItem>
            <GridItem w='100%' ><Text fontSize='3xl'>(3xl) In love with React & Next</Text></GridItem>
            <GridItem w='100%' ><Text fontSize='3xl'>(3xl) In love with React & Next</Text></GridItem>
            <GridItem w='100%' ><Text fontSize='3xl'>(3xl) In love with React & Next</Text></GridItem>
            
          </Grid>
          <Grid templateColumns='repeat(1, 1fr)' gap={6}></Grid>
          </Box> */}
        </Container>
        <Container maxW="4xl"  marginTop="100px">
      <Tabs variant='enclosed'>
  <TabList>
    <Tab><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e63a671191f2adc6411e428_proposals%20icon%20-%20green.svg' alt='pi'/><Spacer/><Text>Proposals</Text></Tab>
    <Tab><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7ce5e2feb667_icon%202.svg' alt='ci'/><Spacer/><Text>Contracts</Text></Tab>
    <Tab><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e72bbe6e8264bb7bbfe4820_dark-forms.svg' alt='ti'/><Spacer/><Text>Clients CRM</Text></Tab>
    <Tab><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c6fccfeb664_icon%204.svg' alt='ii'/><Spacer/><Text>Time Tracking</Text></Tab>
    <Tab><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7cc211feb68d_icon%205.svg' alt='ti'/><Spacer/><Text>Invoices</Text></Tab>
    <Tab><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c852bfeb68c_icon%206.svg' alt='ai'/><Spacer/><Text>Task Tracking</Text></Tab>
    <Tab><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7cee21feb676_icon%207.svg' alt='fi'/><Spacer/><Text>Accounting & Taxes</Text></Tab>
    <Tab><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e72bbe6e8264bb7bbfe4820_dark-forms.svg' alt='pi'/><Spacer/><Text>Forms</Text></Tab>
  </TabList>
  <TabPanels>
    <TabPanel>
    <Text fontSize='3xl' textAlign="left">Win More Work</Text><Text fontSize='xl'textAlign="left">With just a few clicks, you can craft structured proposals with clear estimates to close your deals faster.</Text>
        <img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/6050acdd031b9f58db100f72_Graphic_Proposal-opt.png" alt="pi"/>

    </TabPanel>
    <TabPanel>
    <Text fontSize='3xl' textAlign="left">Protect Your Business</Text><Text fontSize='xl'textAlign="left">Simply answer a few questions to generate an already vetted contract template. Once you hit send, the contract can be e-signed in minutes.</Text>
        <img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/6050ad775d1c278c443c76d6_Graphic_Contact-opt-p-500.png" alt="pi"/>
    </TabPanel>
    <TabPanel>
    <Text fontSize='3xl' textAlign="left">Stay Organized</Text><Text fontSize='xl'textAlign="left">Manage your clients and ongoing projects all in one place. Organize all the documents, files and payments together for you and your client.</Text>
        <img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c5ad3feb65b_Graphic_Projects-min.png" alt="pi"/>
    </TabPanel>
    <TabPanel>
    <Text fontSize='3xl' textAlign="left">Keep It Simple</Text><Text fontSize='xl'textAlign="left">Easily track the time you're working, automatically populate timesheets and seamlessly switch between your projects for the day.</Text>
        <img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/60241fc5e974b72f0865513b_Graphic_Invoice-min.png" alt="pi"/>
    </TabPanel>
    <TabPanel>
    <Text fontSize='3xl' textAlign="left">Get Paid Faster</Text><Text fontSize='xl'textAlign="left">Create and customize invoices, receive updates about payment timelines, and have automatic payment reminders sent on your behalf.</Text>
        <img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/6050ad775d1c278c443c76d6_Graphic_Contact-opt-p-500.png" alt="pi"/>
    </TabPanel>
    <TabPanel>
    <Text fontSize='3xl' textAlign="left">Be More Effective</Text><Text fontSize='xl'textAlign="left">Give your day more structure with simple task management on your projects. Track time for each task and stay up-to-date.</Text>
        <img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/6050aec11e1747965bfc16a5_task-management-graphic-opt.png" alt="pi"/>
      </TabPanel>
      <TabPanel>
      <Text fontSize='3xl' textAlign="left">Automate Your Finances</Text><Text fontSize='xl'textAlign="left">Don't worry about freelance finances when you have automatic expense tracking, income reporting and estimated tax planning.</Text>
        <img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/6050af07c36c35952ad19fbe_Graphic_Accounting-opt.png" alt="pi"/>
    </TabPanel>
    <TabPanel>
    <Text fontSize='3xl' textAlign="left">Ask Your Clients</Text><Text fontSize='xl'textAlign="left">Create your own customized forms and questionnaires for clients and kicking off new projects</Text>
        <img src="https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/6050af50cd2ff1fc7708d532_home-forms-opt.png" alt="pi"/>
    </TabPanel>
  </TabPanels>
</Tabs>
<Container>
<Text fontSize='3xl' marginTop="100px">Trusted by 500K+ self-employed workers
and small businesses</Text>
<Text fontSize="lg" marginTop="20px">Whether you’re just getting started or your business is booming, Bonsai has you covered.</Text>
</Container>

</Container>
<Container maxW="5xl" marginRight="100px" marginTop="100px">
<Grid templateColumns='repeat(2, 1fr)' gap={20}>
  <GridItem w='100%' ><Img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7ca8ebfeb662_ill_Built%20for_4%20block.svg' alt='mi'/><Spacer /><Text fontSize='3xl' textAlign="left" marginTop="20px">Built for All Work Types</Text><Text fontSize='lg' textAlign="left">We proudly back people with wide-ranging businesses from design and marketing to development, writing, and photography.</Text></GridItem>
  <GridItem w='100%' ><Img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c967efeb661_ill_Global%20Support_4%20block.svg' alt='mii'/><Text fontSize='3xl' textAlign="left" marginTop="20px">Built for Global Businesses</Text><Text fontSize='lg' textAlign="left">Bonsai has international coverage across the United States, Canada, UK, Australia and more, with 180 currencies supported.</Text></GridItem>
  
</Grid>
<Text fontSize='3xl' marginTop="100px">Don't just take it from us.</Text>
</Container>
<Container maxW="6xl" marginTop="100px">
<Grid templateColumns='repeat(2, 1fr)' gap={6} >
  <GridItem w='100%'  boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" borderRadius="10px"><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c29a9feb660_quote-icon.svg' alt='bi'/><br/><Text fontSize='xl'>"For all my Freelancer / Small Agency followers, I wish I’d discovered @bonsaiinc long ago - great templated contract"</Text><Text fontSize='2xl' as="b" >David Mihm</Text><Text fontSize='lg'>Founder of TidingsCo</Text></GridItem>
  <GridItem w='100%'  boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" borderRadius="10px"><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c29a9feb660_quote-icon.svg' alt='bi'/><br/><Text fontSize='xl'>"@bonsaiinc is probably my all time favorite freelance tool. hands down. the team is super responsive and accessible when i have questions."</Text><Text fontSize='2xl' as="b" >David Urbinati</Text><Text fontSize='lg'>Motion design / animation</Text></GridItem>
 
</Grid>
<Grid templateColumns='repeat(2, 1fr)' gap={4} marginTop="50px" >
  <GridItem w='100%'  boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" borderRadius="10px"><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c29a9feb660_quote-icon.svg' alt='bi'/><br/><Text fontSize='xl'>"Anyone doing #freelance work should use @bonsaiinc for contracts/payment. It's amazing and saves boatloads of time."</Text><Text fontSize='2xl' as="b" >Nathanael Smith</Text><Text fontSize='lg'>Product Design</Text></GridItem>
  <GridItem w='100%' boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" borderRadius="10px"><img src='https://assets-global.website-files.com/58868bcd2ef4daaf0f072900/5e5fd7c602ca7c29a9feb660_quote-icon.svg' alt='bi'/><br/><Text fontSize='xl'>"Wow, @bonsaiinc's contracts are completely wonderful. If you freelance you have to check it out. Really lovely experience. h/t @hemeon""</Text><Text fontSize='2xl' as="b" >Jon Gold</Text><Text fontSize='lg'>Designer and engineer</Text></GridItem>
 
</Grid>
<Box marginTop="100px" >
<Grid templateColumns='repeat(2, 1fr)'  justifyContent="center" h='200' bg="#f2faff" borderRadius="10px">
  <GridItem w='100%' textAlign="left" paddingLeft="100px" marginTop="70px"><Text fontSize='3xl'>It’s your business.</Text><Text fontSize='3xl'>We’re here to help it grow.</Text></GridItem>
  
  <GridItem w='100%' marginTop="100px" ><NavLink to="/Login"><Button bg='#01b289' color="white">START FREE</Button></NavLink></GridItem>
</Grid>
</Box>

</Container>


      </>
      

    )
  }
}
