
import './App.css';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import AllRoutes from './Routes/AllRoutes';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
        <AllRoutes />
        <Footer />
      </header>
    </div>
  );
}

export default App;
