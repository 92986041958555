import React from 'react';
import { Route, Routes } from "react-router-dom"
import Landing from '../Components/Landing';
import Login from '../Components/Login';
import Pricing from '../Components/Pricing';


const AllRoutes=()=>{
     return (
        <div>
        <Routes>
            <Route path="/" element={<Landing />}/>
            <Route path='/Login' element={<Login/>}/>
            <Route path='/Pricing' element={<Pricing/>}/>
        </Routes>
        </div>
     )
}
export default AllRoutes