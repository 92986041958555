import React from 'react';
import { Box, Button,  Container, Divider, Flex, Grid, GridItem, Heading, Spacer, Text } from "@chakra-ui/react"

function Pricing() {
    return (
        <>
            <Container maxW="7xl" marginTop="200px">
                <Text fontSize='4xl'>Plans & Pricing</Text>

                <Grid templateColumns='repeat(3, 1fr)' gap={10} marginTop="100px" >
                    <GridItem w='100%' boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" border="1px solid #01b289">
                        <Container maxW='300px' >
                            <Text fontSize='xl' marginTop="100px" textAlign="left">Starter</Text>
                            <Text fontSize='md' textAlign="left">Ideal for freelancers and contractors just starting out.</Text>
                            <Text fontSize='2xl' marginTop="20px" textAlign="left">$ 24 /Month</Text>
                            <Divider orientation='horizontal' />
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ All Templates</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Unlimited Clients & Projects</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Invoicing & Payments</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Proposals & Contracts</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Tasks & Time Tracking</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Client CRM</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Expense Tracking</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Upto 5 Project Collaborators</Text>
                            <Button bg="#01b289" color="white" marginTop="250px" padding="10px 70px" marginBottom="50px">START FREE</Button>
                        </Container>
                    </GridItem>
                    <GridItem w='100%' boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" border="1px solid #01b289">
                        <Box bg='#01b289' w='100%' p={4} color='white' textAlign="center" >MOST POPULAR
                        </Box>

                        <Container maxW='300px' >
                            <Text fontSize='xl' marginTop="50px" textAlign="left">Professional</Text>
                            <Text fontSize='md' textAlign="left">Everything a growing independent business needs to thrive.</Text>
                            <Text fontSize='2xl' marginTop="20px" textAlign="left">$ 39 /Month</Text>
                            <Divider orientation='horizontal' />
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Everything in Starter plus...</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Custom Branding</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Forms & Questionnaires</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Workflow Automations</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Client Portal</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ QuickBooks Integration</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Calendly Integration</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Zapier Integration</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Up to 15 Project Collaborators</Text>
                            <Button bg="#01b289" color="white" padding="10px 70px" marginTop="190px" marginBottom="50px">START FREE</Button>



                        </Container>
                    </GridItem>
                    <GridItem w='100%' boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" border="1px solid #01b289">
                        <Container maxW='300px' >
                            <Text fontSize='xl' marginTop="100px" textAlign="left">Business</Text>
                            <Text fontSize='md' textAlign="left">The perfect package for small businesses and agencies.</Text>
                            <Text fontSize='2xl' marginTop="20px" textAlign="left">$ 79 /Month</Text>
                            <Divider orientation='horizontal' />
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Everything in Starter and Professional plus...</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Subcontractor Management</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Hiring Agreement Templates (1099 contracts)</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Subcontractor Onboarding</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Talent Pool</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ 3 Team Seats (additional seats $9/month)</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Accountant Access</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Connect Multiple Bank Accounts</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Unlimited Subcontractors</Text>
                            <Text fontSize='xl' marginTop="20px" textAlign="left">✓ Unlimited Project Collaborators</Text>
                            <Button bg="#01b289" color="white" padding="10px 70px" marginBottom="50px">START FREE</Button>


                        </Container>

                    </GridItem>

                </Grid>
                <Text fontSize='3xl' marginTop="100px">Super charge your work with add-ons</Text>
                <Box border="1px solid #01b289" w='100%' h="150px" marginTop="50px">
                    <Flex minWidth='max-content' alignItems='center' gap='2'>
                        <Box p='2'>
                            <Heading size='md' textAlign="left" marginTop="40px">Collaborators</Heading>
                            <Text size='sm' textAlign="left" >Invite other users to specific projects for limited access and functionality.</Text>
                        </Box>
                        <Spacer />
                        <Heading size='2xl' marginTop="40px" as="b" marginRight="20px">Free</Heading>
                    </Flex>
                </Box>
                <Box border="1px solid #01b289" w='100%' h="150px" marginTop="50px">
                    <Flex minWidth='max-content' alignItems='center' gap='2'>
                        <Box p='2'>
                            <Heading size='md' textAlign="left" marginTop="40px">Partners</Heading>
                            <Text size='sm' textAlign="left" >Invite other users for full account access and company management.</Text>
                        </Box>
                        <Spacer />
                        <Heading size='2xl' marginTop="40px" as="b" marginRight="20px">$9</Heading>
                    </Flex>
                </Box>
                <Box border="1px solid #01b289" w='100%' h="150px" marginTop="50px">
                    <Flex minWidth='max-content' alignItems='center' gap='2'>
                        <Box p='2'>
                            <Heading size='md' textAlign="left" marginTop="40px">Collaborators</Heading>
                            <Text size='sm' textAlign="left" >Invite other users to specific projects for limited access and functionality.</Text>
                        </Box>
                        <Spacer />
                        <Heading size='2xl' marginTop="40px" as="b" marginRight="20px">$10</Heading>
                    </Flex>
                </Box>
                <Box marginTop="100px" >
                    <Grid templateColumns='repeat(2, 1fr)' justifyContent="center" h='200' bg="#f2faff" borderRadius="10px">
                        <GridItem w='100%' textAlign="left" paddingLeft="100px" marginTop="70px"><Text fontSize='3xl'>It’s your business.</Text><Text fontSize='3xl'>We’re here to help it grow.</Text></GridItem>

                        <GridItem w='100%' marginTop="100px" ><Button bg='#01b289' color="white">START FREE</Button></GridItem>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}
export default Pricing